import React, { useState, useCallback } from "react";
import { ReactComponent as LogoSVG } from "../assets/svg/logo6.svg";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from '@mui/icons-material/Clear'; // Import ClearIcon
import { useNavigate, Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import "../App.css";
import ReactGA from "react-ga4";
import useAnalyticsPageTracker from '../hooks/useAnalyticsPageTracker';
import { Typography, InputAdornment } from "@mui/material";
import { debounce } from 'lodash';

function TechScouting() {
  useAnalyticsPageTracker();
  
  // State to track the selected button (TechScouting or TechReadiness)
  const [selectedButton, setSelectedButton] = useState("TechScouting");
  
  // State to track the search term input by the user
  const [searchTerm, setSearchTerm] = useState("");
  
  // State to store the autocomplete options fetched from the API
  const [options, setOptions] = useState([]);
  
  // State to indicate if the autocomplete options are loading
  const [loading, setLoading] = useState(false);
  
  // State to track the current value of the Autocomplete (can be a string or an object)
  const [value, setValue] = useState(null);
  
  const navigate = useNavigate();

  // Handler for input changes in the Autocomplete
  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    debouncedFetchAutocompleteOptions(newInputValue);
    
    // If the input is cleared, reset the selected value
    if (!newInputValue) {
      setValue(null);
    }
  };

  // Handler for option selection in the Autocomplete
  // const handleOptionChange = (event, newValue) => {
  //   setValue(newValue);
    
  //   // If an option is selected, set the searchTerm based on Abbv or Phrase
  //   if (typeof newValue === 'object' && newValue !== null) {
  //     setSearchTerm(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //   } else if (typeof newValue === 'string') {
  //     // If a string is entered (free solo), use it directly
  //     setSearchTerm(newValue);
  //   } else {
  //     setSearchTerm('');
  //   }
  // };
  const handleOptionChange = (event, newValue) => {
    setValue(newValue);
    
    // If an option is selected, set the searchTerm based on Abbv or Phrase
    let termToSearch = '';
    if (typeof newValue === 'object' && newValue !== null) {
      termToSearch = newValue.Abbv ? newValue.Abbv : newValue.Phrase;
      setSearchTerm(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
    } else if (typeof newValue === 'string') {
      // If a string is entered (free solo), use it directly
      termToSearch = newValue;
      setSearchTerm(newValue);
    }
  
    if (termToSearch) {
      // Navigate to the appropriate page based on the selected button
      if (selectedButton === "TechScouting") {
        navigate(`/TechScoutingResult?keywords=${encodeURIComponent(termToSearch)}`);
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(termToSearch)}`);
      }
    } else {
      setSearchTerm('');
    }
  };

  // Function to fetch autocomplete options from the API
  const fetchAutocompleteOptions = async (prefix) => {
    if (!prefix) return;
    setLoading(true);

    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v1/autocomplete`,
        {
          headers,
          params: { prefix: prefix },
        }
      );
      setOptions(response.data.results);
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchAutocompleteOptions = useCallback(
    debounce((newInputValue) => fetchAutocompleteOptions(newInputValue), 300),
    []
  );

  // Function to track events with Google Analytics
  const TrackGoogleAnalyticsEvent = (category, event_name, label, data) => {
    let event_params = {
      category,
      label,
      ...data,
    };
    // Send GA4 Event
    ReactGA.event(event_name, event_params);
  };

  // Handler for key press events in the search input
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      let termToSearch;
      
      // Determine the term to search based on whether an option is selected
      if (value && typeof value === 'object') {
        termToSearch = value.Abbv ? value.Abbv : value.Phrase;
      } else {
        termToSearch = searchTerm;
      }

      const category = selectedButton; // "TechScouting" or "TechReadiness"
      const event_name = "search_performed";

      // Triggering the GA event
      TrackGoogleAnalyticsEvent(category, event_name, "Search Term", { search_term: termToSearch });

      // Navigate to the appropriate page based on the selected button
      if (selectedButton === "TechScouting") {
        navigate(
          `/TechScoutingResult?keywords=${encodeURIComponent(termToSearch)}`
        );
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(termToSearch)}`);
      }
    }
  };

  // Handlers for button clicks to switch between TechScouting and TechReadiness
  const handleTechScoutingClick = () => {
    setSelectedButton("TechScouting");
  };

  const handleTechReadinessClick = () => {
    setSelectedButton("TechReadiness");
  };

  return (
    <div className="container" sx={{ marginTop: "100px" }}>
      <div className="epic2-background">
        <Link to="/">
          <LogoSVG className="logo" />
        </Link>
        <div className="content-wrapper">
          <div className="sentence">
            Navigate the future of technology, <br />
            Today.
          </div>

          <div className="button-group">
            <Button
              variant="contained"
              className={selectedButton === "TechScouting" ? "active" : ""}
              onClick={handleTechScoutingClick}
              style={{ padding: "0" }}
            >
              Tech Scouting
            </Button>
            <Button
              variant="contained"
              className={selectedButton === "TechReadiness" ? "active" : ""}
              onClick={handleTechReadinessClick}
            >
              Tech Readiness Assessing
            </Button>
          </div>
          
          <div className="search-bar">
            <Autocomplete
              freeSolo
              options={options}
              getOptionLabel={(option) =>
                typeof option === "string"
                  ? option
                  : option.Abbv
                  ? `${option.Abbv} - ${option.Phrase}`
                  : option.Phrase
              } 
              onInputChange={handleInputChange}
              onChange={handleOptionChange}
              loading={loading}
              value={value}
              clearIcon={<ClearIcon style={{ color: 'white' }} />} // Make clear icon white
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body1" style={{ fontWeight: "bold", marginRight: "10px" }}>
                    {option.Abbv}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {option.Phrase}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  placeholder="Enter one or more fields of interest, separated by ,"
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className="search-icon" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                    sx: {
                      width: "550px", // Increased width
                      borderRadius: "4px 0px 0px 0px",
                      opacity: 0.8,
                      backgroundColor: "#415250",
                      color: "#FFFFFF",
                      display: "flex", // Ensure content alignment
                      alignItems: "center", // Center the content vertically
                      justifyContent: "center", // Center the content horizontally
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent", // Remove border if needed
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                    color: 'white', // Ensure text is white
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechScouting;
