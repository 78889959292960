import React, { useState, useEffect, useRef, useCallback } from "react";
import "../App.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import InputBase from "@mui/material/InputBase";
import { ReactComponent as LogoSVG } from "../assets/svg/logo5.svg";
import { ReactComponent as NewSVG } from "../assets/svg/bg_epic2-2.svg";
import { ReactComponent as EmptySVG } from "../assets/svg/img-empty.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  ButtonGroup,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import * as echarts from "echarts/core";
import axios from "axios";
import { LineChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import ReactEcharts from "echarts-for-react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ReactGA from "react-ga4";
import { jwtDecode } from "jwt-decode";
import { debounce } from 'lodash';

echarts.use([LineChart, GridComponent, TooltipComponent, CanvasRenderer]);

const getOption = (data) => {
  const maxDataValue = Math.max(...data);
  const minDataValue = Math.min(...data);

  return {
    grid: {
      top: "15px",
      left: "15px",
      right: "15px",
      bottom: "9px",
    },
    xAxis: {
      type: "category",
      data: data,
      show: false,
    },
    yAxis: {
      min: minDataValue,
      max: maxDataValue,
      type: "value",
      show: false, // hide y-axis
    },
    series: [
      {
        data: data,
        type: "line",
        smooth: true, // optional, for smooth lines
        itemStyle: {
          color: "#3D766F",
        },
        lineStyle: {
          width: 2,
          color: "#3D766F",
        },
        showSymbol: false, // This will hide the data point symbols
      },
    ],
  };
};

const IconTableCell = styled(TableCell)({
  height: "68px",
  marginTop: "8px",
});

const StyledTableCell = styled(TableCell)(({ theme, columnType }) => ({
  fontSize: 14,
  height: 48,
  "&:last-child": {
    paddingRight: 8,
  },
  ...{
    id: {
      fontFamily: "Roboto",
      fontSize: 14,
      lineHeight: "16px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#00000066",
      fontWeight: "bold",
      width: "4% !important",
    },
    concept: {
      padding: "0px 4px",
      width: "35%",
    },
    keywords: {
      padding: "0px 4px",
      width: "20%",
    },
    "2y": {
      padding: "0px 4px",
      width: "20%",
    },
    "1y": {
      padding: "0x 4px",
      width: "20%",
    },
    icon: {
      padding: "16px 0",
      textAlign: "center",
      width: "5%",
    },
  }[columnType || "concept"],
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "none",
    boxShadow: "0px -1px 0px 0px #0000001A inset",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
  },
}));
const StyledTableCellRight = styled(TableCell)(({ theme, columnType }) => ({
  fontSize: 14,
  height: 48,
  backgroundColor: "#FAFAFA",
  ...{
    keywords: {
      padding: "0px 4px",
      width: "20%",
    },
    articles: {
      padding: "0px 4px",
      width: "20%",
    },
    chart: {
      padding: "0px 4px",
      width: "100px",
    },
    icon: {
      padding: "16px 0",
      textAlign: "center",
      width: "5%",
    },
  }[columnType || "concept"],
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "none",
    boxShadow: "0px -1px 0px 0px #0000001A inset",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
  },
}));

const StyledTableCellBelow = styled(TableCell)(({ theme, columnType }) => ({
  fontSize: 14,
  height: 48,
  backgroundColor: "#FFFFFF",
  ...{
    id: {
      padding: "0px 4px",
      width: "5%",
    },
    keyword: {
      padding: "0px 4px",
      width: "55%",
    },
    articles: {
      padding: "0px 4px",
      width: "30%",
    },
    year: {
      padding: "16px 0",
      textAlign: "center",
      width: "10%",
    },
  }[columnType || "concept"],
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold", // This is the corrected line
    fontSize: "18px",
    borderBottom: "none",
    boxShadow: "0px -1px 0px 0px #0000001A inset",
    padding: "10px",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
  },
}));
const StyledTableCellSkeleton = styled(StyledTableCellBelow)(({ theme }) => ({
  padding: 10,
  borderBottom: "none",
  boxShadow: "none",
  backgroundColor: "transparent",
  [`&.${tableCellClasses.head}`]: {
    padding: "0",
    boxShadow: "none",
  },
  [`&.${tableCellClasses.body}`]: {},
}));

const StyledTableRow = styled(TableRow)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? "#F5F5F580" : "transparent",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Navbar({ openModal }) {
  return (
    <div className="navbar">
      <Link to="/" style={{ pointerEvents: "auto" }}>
        <LogoSVG />
      </Link>

      <div className="search-icon-wrapper" onClick={openModal}>
        <SearchIcon className="tech-search-icon" />
      </div>
    </div>
  );
}

function RightTable({
  selectedConcept,
  data,
  searchTerm,
  isLoading,
  isLoadingRight,
  selectedLabel,
}) {
  const [visibleData, setVisibleData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [highestMilestone, setHighestMilestone] = useState(0);
  useEffect(() => {
    if (data && data.total > 0) {
      setVisibleData(data);
    } else {
      setVisibleData([]);
    }
  }, [data]);
  const tableRef = useRef(null);
  const headerStyle = {
    width: "342px",
    height: "40px",
    fontFamily: "'Glory', sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
  };

  const containerRef = useRef(null);
  const echartRef = useRef(null);
  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleScrollStop = () => {
    if (highestMilestone > 0) {
      ReactGA.event({
        category: "Table Interaction",
        action: "Scroll Stop",
        label: `Right Table Scrolled ${highestMilestone}%`,
      });
      setHighestMilestone(0);
    }
  };

  const debouncedHandleScroll = debounce(handleScrollStop, 150);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const maxScroll = scrollHeight - clientHeight;
    const scrolledPercentage = Math.round((scrollTop / maxScroll) * 100);

    const milestones = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

    if (
      milestones.includes(scrolledPercentage) &&
      scrolledPercentage > highestMilestone
    ) {
      setHighestMilestone(scrolledPercentage);
    }

    debouncedHandleScroll();
  };
  return (
    <div>
      <div>
        <Table>
          <TableCell
            style={headerStyle}
            sx={{
              borderTop: "5px solid #3D766F",
              borderBottom: "none",
              paddingBottom: "0px",
              background: "#FAFAFA",
              width: "100%",
            }}
            // colSpan={5}
          >
            <div>
              {selectedLabel}
              <div
                style={{
                  color: "#00000066",
                  fontWeight: "400",
                  paddingTop: "3px",
                }}
              >
                includes {data.total} keywords
              </div>
            </div>
          </TableCell>
        </Table>
      </div>
      <TableContainer
        ref={tableRef}
        component={Paper}
        elevation={1}
        style={{
          maxHeight: "795px",
          overflowY: "auto",
          width: "100%",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
        onScroll={handleScroll}
      >
        <Table
          stickyHeader
          sx={{
            width: "100%",
            background: "#FAFAFA",
          }}
          aria-label="customized table"
        >
          <TableHead sx={{ background: "#FAFAFA", borderTop: "none" }}>
            <TableRow>
              <TableCell sx={{ background: "#FAFAFA" }}>#</TableCell>
              <StyledTableCellRight columnType="icon"></StyledTableCellRight>
              <StyledTableCellRight>Keyword</StyledTableCellRight>
              <StyledTableCellRight>
                Appear in number of articles
              </StyledTableCellRight>
              <StyledTableCellRight>2y amount trends</StyledTableCellRight>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isLoadingRight
              ? Array(9)
                  .fill(0)
                  .map((_, idx) => <StyledTableRowSkeletonRight key={idx} />)
              : visibleData.keywords?.map((item, index) => (
                  <StyledTableRow
                    key={index}
                    onClick={() => setSelectedRowIndex(index)}
                    isSelected={selectedRowIndex === index}
                    onMouseEnter={() => setHoveredRowIndex(index)}
                    onMouseLeave={() => setHoveredRowIndex(null)}
                  >
                    <StyledTableCellRight
                      columnType="icon"
                      style={{
                        backgroundColor: "#FAFAFA",
                        color:
                          hoveredRowIndex === index ? "#3D766F" : "inherit",
                      }}
                    >
                      {index + 1}
                    </StyledTableCellRight>
                    <IconTableCell
                      style={{
                        padding: "0px",
                        color: "#3D766F",
                        borderBottom: "none",
                        backgroundColor: "#FAFAFA",
                      }}
                    >
                      <Link
                        to={`/TechReadiness?keyword=${item.name}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={() => {
                          ReactGA.event({
                            category: "Navigation",
                            action: "Right Table Keyword to TRA Click",
                            label: item.name,
                          });
                        }}
                      >
                        <InsertChartOutlinedIcon />
                      </Link>
                    </IconTableCell>
                    <StyledTableCell
                      columnType="keywords"
                      style={{
                        backgroundColor: "#FAFAFA",
                        color:
                          hoveredRowIndex === index ? "#3D766F" : "inherit",
                      }}
                    >
                      <Link
                        to={`/TechReadiness?keyword=${item.name}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                        onClick={() => {
                          ReactGA.event({
                            category: "Navigation",
                            action: "Right Table Keyword to TRA Click",
                            label: item.name,
                          });
                        }}
                      >
                        {item.name}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCellRight columnType="keywords">
                      <Link
                        to={`/TechScoutingArticle?keyword=${item.name}&cluster=${selectedConcept}&searchTerm=${searchTerm}&label=${selectedLabel}`}
                        style={{
                          textDecoration: "none",
                          color:
                            hoveredRowIndex === index ? "#3D766F" : "inherit",
                        }}
                        onClick={() => {
                          const gaLabel = `Keyword: ${item.name}, Concept: ${selectedConcept}, Search Term: ${searchTerm}`;
                          ReactGA.event({
                            category: "Article Navigation",
                            action: "Click",
                            label: gaLabel,
                          });
                        }}
                      >
                        {item.appear_times}
                      </Link>
                    </StyledTableCellRight>
                    <StyledTableCellRight
                      style={{ width: "15%", height: "70%" }}
                    >
                      <div
                        ref={containerRef}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#efefef",
                          width: "90%",
                          height: "100%",
                        }}
                      >
                        <ReactEcharts
                          ref={echartRef}
                          key={JSON.stringify(data)}
                          style={{ height: "50px", width: "200px" }}
                          option={getOption(item.trend_changes)}
                        />
                      </div>
                    </StyledTableCellRight>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

const HollowInfoIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      stroke="black"
      strokeWidth="2"
      fill="transparent"
    />
    <circle cx="12" cy="9" r="1" fill="black" />
    <line
      x1="12"
      y1="12"
      x2="12"
      y2="16"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

function LeftTable({
  onRowClick,
  onNameClick,
  selectedRow,
  data,
  isLoading,
  sortOrder2y,
  setSortOrder2y,
  setSortOrder1y,
  sortOrder1y,
  sortOrderAppearTimes,
  setSortOrderAppearTimes,
}) {
  // const defaultSort = data
  //   // .slice()
  //   .sort((a, b) => parseInt(b.n_keywords, 10) - parseInt(a.n_keywords, 10));
  const [visibleData, setVisibleData] = useState(data ? data.clusters : []);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const tableRef = useRef(null);
  const [lastSortedColumn, setLastSortedColumn] = useState(null);
  useEffect(() => {
    if (data) {
      setVisibleData(data.clusters);
    }
  }, [data]);
  const sortTable = (key) => {
    const sortedData = [...visibleData];
    if (lastSortedColumn !== key) {
      if (key !== "year-2") setSortOrder2y("default");
      if (key !== "year-1") setSortOrder1y("default");
      if (key !== "appear_times") setSortOrderAppearTimes("default");

      setLastSortedColumn(key);
    }

    // updateSortState(key);
    const sortAndSetState = (sortStateHook, defaultFunc, descFunc, ascFunc) => {
      const currentState = sortStateHook[0];
      if (currentState === "default") {
        descFunc();
        sortStateHook[1]("desc");
      } else if (currentState === "desc") {
        ascFunc();
        sortStateHook[1]("asc");
      } else {
        defaultFunc();
        sortStateHook[1]("default");
      }
    };

    const sortDefault = () => {
      sortedData.sort((a, b) => b.n_keywords - a.n_keywords);
    };

    // Sorting logic for 'appear_times'
    if (key === "appear_times") {
      sortAndSetState(
        [sortOrderAppearTimes, setSortOrderAppearTimes],
        sortDefault,
        () => sortedData.sort((a, b) => b.n_keywords - a.n_keywords), // Descending
        () => sortedData.sort((a, b) => a.n_keywords - b.n_keywords) // Ascending
      );
    }

    // Sorting logic for 'year-2'
    if (key === "year-2") {
      sortAndSetState(
        [sortOrder2y, setSortOrder2y],
        sortDefault,
        () =>
          sortedData.sort(
            (a, b) => b.two_year_trend_change - a.two_year_trend_change
          ), // Descending
        () =>
          sortedData.sort(
            (a, b) => a.two_year_trend_change - b.two_year_trend_change
          ) // Ascending
      );
    }

    // Sorting logic for 'year-1'
    if (key === "year-1") {
      sortAndSetState(
        [sortOrder1y, setSortOrder1y],
        sortDefault,
        () =>
          sortedData.sort(
            (a, b) => b.one_year_trend_change - a.one_year_trend_change
          ), // Descending
        () =>
          sortedData.sort(
            (a, b) => a.one_year_trend_change - b.one_year_trend_change
          ) // Ascending
      );
    }
    setVisibleData(sortedData);
    onRowClick(sortedData[0].label);
    onNameClick(sortedData[0].name);
  };
  const [highestMilestone, setHighestMilestone] = useState(0);
  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleScrollStop = () => {
    if (highestMilestone > 0) {
      ReactGA.event({
        category: "Table Interaction",
        action: "Scroll Stop",
        label: `Left Table Scrolled ${highestMilestone}%`,
      });
      setHighestMilestone(0);
    }
  };

  const debouncedHandleScroll = debounce(handleScrollStop, 150);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const maxScroll = scrollHeight - clientHeight;
    const scrolledPercentage = Math.round((scrollTop / maxScroll) * 100);

    const milestones = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

    if (
      milestones.includes(scrolledPercentage) &&
      scrolledPercentage > highestMilestone
    ) {
      setHighestMilestone(scrolledPercentage);
    }

    debouncedHandleScroll();
  };

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      elevation={3}
      style={{
        maxHeight: "870px",
        overflowY: "auto",
        width: "100%",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        // Hide scrollbar for Firefox
        scrollbarWidth: "none",
      }}
      onScroll={handleScroll}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <StyledTableCell>Concepts</StyledTableCell>
            <StyledTableCell
              onClick={() => {
                sortTable("appear_times");
                ReactGA.event({
                  category: "Table Interaction",
                  action: "Sort",
                  label: "Technical Concept table appear_times",
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Relevant Keywords
                {sortOrderAppearTimes === "desc" ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </div>
            </StyledTableCell>

            <StyledTableCell
              onClick={() => {
                sortTable("year-2");
                ReactGA.event({
                  category: "Table Interaction",
                  action: "Sort",
                  label: "Technical Concept table 2year",
                });
              }}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                2y
                <Tooltip
                  title="The percentage change in the proportion of research focus on different topics or areas over 2 years"
                  arrow
                >
                  <span
                    style={{
                      marginLeft: "4px",
                      paddingTop: "5px",
                      verticalAlign: "middle",
                    }}
                  >
                    <HollowInfoIcon />
                  </span>
                </Tooltip>
                {sortOrder2y === "desc" ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </div>
            </StyledTableCell>
            <StyledTableCell
              onClick={() => {
                sortTable("year-1");
                ReactGA.event({
                  category: "Table Interaction",
                  action: "Sort",
                  label: "Technical Concept table 1year",
                });
              }}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                }}
              >
                1y
                <Tooltip
                  title="The percentage change in the proportion of research focus on different topics or areas over 1 year"
                  arrow
                >
                  <span
                    style={{
                      marginLeft: "4px",
                      paddingTop: "5px",
                      verticalAlign: "middle",
                    }}
                  >
                    <HollowInfoIcon />
                  </span>
                </Tooltip>
                {sortOrder1y === "desc" ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )}
              </div>
            </StyledTableCell>

            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? Array(10)
                .fill(0)
                .map((_, idx) => <StyledTableRowSkeleton key={idx} />)
            : visibleData?.map((item, index) => (
                <StyledTableRow
                  key={index}
                  onClick={() => {
                    setSelectedRowIndex(index);
                    onRowClick(item.label);
                    onNameClick(item.name);
                    ReactGA.event({
                      category: "Row Interaction",
                      action: "Click",
                      label: `Cluster Name: ${item.name}`,
                    });
                  }}
                  isSelected={selectedRowIndex === index}
                >
                  <StyledTableCell columnType="id">
                    {item.name ? index + 1 : ""}
                  </StyledTableCell>
                  <StyledTableCell
                    columnType="concept"
                    style={{
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Tooltip title={item.name} placement="bottom-start">
                      <span>{item.name}</span>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell columnType="keywords">
                    {item.n_keywords}
                  </StyledTableCell>
                  <StyledTableCell
                    columnType="2y"
                    style={{ paddingLeft: "10px" }}
                  >
                    {item.name ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {parseFloat(item.two_year_trend_change) * 100 < 0 ? (
                          <ArrowDownwardIcon
                            style={{ color: "red", marginRight: "4px" }}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            style={{ color: "darkgreen", marginRight: "4px" }}
                          />
                        )}
                        {Math.abs(
                          parseFloat(item.two_year_trend_change) * 100
                        ).toFixed(0) + "%"}
                      </div>
                    ) : (
                      ""
                    )}
                  </StyledTableCell>

                  <StyledTableCell columnType="1y">
                    {item.name ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {item.one_year_trend_change < 0 ? (
                          <ArrowDownwardIcon
                            style={{ color: "red", marginRight: "4px" }}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            style={{ color: "darkgreen", marginRight: "4px" }}
                          />
                        )}
                        {Math.abs(item.one_year_trend_change * 100).toFixed(0) +
                          "%"}
                      </div>
                    ) : (
                      ""
                    )}
                  </StyledTableCell>

                  <StyledTableCell columnType="icon">
                    {selectedRowIndex === index && <ArrowRightIcon />}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
function StyledTableRowSkeleton() {
  return (
    <StyledTableRow>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell keyword-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell trend-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell trend-skeleton"></div>
      </StyledTableCellSkeleton>
    </StyledTableRow>
  );
}
function StyledTableRowSkeletonRight() {
  return (
    <StyledTableRow>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell keyword-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell small-skeleton"></div>
      </StyledTableCellSkeleton>
      <StyledTableCellSkeleton>
        <div className="skeleton-cell trend-skeleton"></div>
      </StyledTableCellSkeleton>
    </StyledTableRow>
  );
}

function BelowTable({ data, searchTerm, isLoading }) {
  const [visibleData, setVisibleData] = useState(data);
  const [sortOrder2y, setSortOrder2y] = useState("default");
  const [sortOrder1y, setSortOrder1y] = useState("default");
  useEffect(() => {
    setVisibleData(data);
  }, [data]);

  const sortDataBy2y = () => {
    let sortedData = [...visibleData];
    if (sortOrder2y === "default") {
      // First click - sort descending
      sortedData.sort(
        (a, b) => b.two_year_trend_change - a.two_year_trend_change
      );
      setSortOrder2y("desc");
    } else if (sortOrder2y === "desc") {
      // Second click - sort ascending
      sortedData.sort(
        (a, b) => a.two_year_trend_change - b.two_year_trend_change
      );
      setSortOrder2y("asc");
    } else {
      // Third click - return to default (no sorting)
      sortedData = data.slice().sort((a, b) => b.n_keywords - a.n_keywords);
      setSortOrder2y("default");
    }
    setVisibleData(sortedData);
  };

  // Function to handle sorting for 1y column
  const sortDataBy1y = () => {
    let sortedData = [...visibleData];
    if (sortOrder1y === "default") {
      // First click - sort descending
      sortedData.sort(
        (a, b) => b.one_year_trend_change - a.one_year_trend_change
      );
      setSortOrder1y("desc");
    } else if (sortOrder1y === "desc") {
      // Second click - sort ascending
      sortedData.sort(
        (a, b) => a.one_year_trend_change - b.one_year_trend_change
      );
      setSortOrder1y("asc");
    } else {
      // Third click - return to default (no sorting)
      sortedData = data.slice().sort((a, b) => b.n_keywords - a.n_keywords);
      setSortOrder1y("default");
    }
    setVisibleData(sortedData);
  };
  const tableRef = useRef(null);
  const [highestMilestone, setHighestMilestone] = useState(0);
  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const handleScrollStop = () => {
    if (highestMilestone > 0) {
      ReactGA.event({
        category: "Table Interaction",
        action: "Scroll Stop",
        label: `Relevance Table Scrolled ${highestMilestone}%`,
      });
      setHighestMilestone(0);
    }
  };

  const debouncedHandleScroll = debounce(handleScrollStop, 150);

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const maxScroll = scrollHeight - clientHeight;
    const scrolledPercentage = Math.round((scrollTop / maxScroll) * 100);

    const milestones = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

    if (
      milestones.includes(scrolledPercentage) &&
      scrolledPercentage > highestMilestone
    ) {
      setHighestMilestone(scrolledPercentage);
    }

    debouncedHandleScroll();
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      elevation={3}
      style={{ maxHeight: "880px", overflowY: "initial", width: "100%" }}
      onScroll={handleScroll}
    >
      <Table stickyHeader sx={{ width: "100%" }} aria-label="customized table">
        <TableHead style={{ overflowX: "initial" }}>
          <TableRow>
            <StyledTableCellBelow columnType="id">#</StyledTableCellBelow>
            <StyledTableCellBelow columnType="keyword">
              Keyword
            </StyledTableCellBelow>
            <StyledTableCellBelow columnType="articles">
              Appear in number of articles
            </StyledTableCellBelow>
            <StyledTableCell>
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={sortDataBy2y}
              >
                2y
                <Tooltip
                  title="The percentage change in the proportion of research focus on different topics or areas over 2 years"
                  arrow
                >
                  <span
                    style={{
                      marginLeft: "4px",
                      paddingTop: "5px",
                      verticalAlign: "middle",
                    }}
                  >
                    <HollowInfoIcon />
                  </span>
                </Tooltip>
                {sortOrder2y === "default" || sortOrder2y === "asc" ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )}
              </div>
            </StyledTableCell>
            <StyledTableCell>
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={sortDataBy1y}
              >
                1y
                <Tooltip
                  title="The percentage change in the proportion of research focus on different topics or areas over 1 year"
                  arrow
                >
                  <span
                    style={{
                      marginLeft: "4px",
                      paddingTop: "5px",
                      verticalAlign: "middle",
                    }}
                  >
                    <HollowInfoIcon />
                  </span>
                </Tooltip>
                {sortOrder1y === "default" || sortOrder1y === "asc" ? (
                  <ArrowUpwardIcon />
                ) : (
                  <ArrowDownwardIcon />
                )}
              </div>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? Array(10)
                .fill(0)
                .map((_, idx) => <StyledTableRowSkeleton key={idx} />)
            : visibleData.map((item, index) => (
                <StyledTableRow
                  key={index}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "grey";
                    e.currentTarget.style.color = "#3D766F";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = ""; // Resetting to original background color
                    e.currentTarget.style.color = ""; // Resetting to original font color
                  }}
                >
                  <StyledTableCellBelow>{index + 1}</StyledTableCellBelow>
                  <StyledTableCellBelow>
                    {(() => {
                      const HoverableLink = () => {
                        const [isLinkHovered, setIsLinkHovered] =
                          useState(false);

                        return (
                          <Link
                            target="_blank"
                            to={`/TechReadiness?keyword=${item.name}`}
                            onClick={() => {
                              ReactGA.event({
                                category: "Navigation",
                                action: "Relavance Table Keyword to TRA Click",
                                label: item.name,
                              });
                            }}
                            style={{
                              color: isLinkHovered ? "#3D766F" : "#000",
                              textDecoration: "none",
                            }}
                            onMouseEnter={() => setIsLinkHovered(true)}
                            onMouseLeave={() => setIsLinkHovered(false)}
                          >
                            {item.name}
                          </Link>
                        );
                      };

                      return <HoverableLink />;
                    })()}
                  </StyledTableCellBelow>{" "}
                  <StyledTableCellBelow>
                    {(() => {
                      const HoverableLink = () => {
                        const [isLinkHovered, setIsLinkHovered] =
                          useState(false);

                        return (
                          <Link
                            target="_blank"
                            to={`/TechScoutingTopArticle?keyword=${item.name}&searchTerm=${searchTerm}`}
                            style={{
                              color: isLinkHovered ? "#3D766F" : "#000",
                              textDecoration: "none",
                            }}
                            onMouseEnter={() => setIsLinkHovered(true)}
                            onMouseLeave={() => setIsLinkHovered(false)}
                            onClick={() => {
                              ReactGA.event({
                                category: "Link Interaction",
                                action: "Click",
                                label: `TechScoutingTopArticle - Keyword: ${item.keyword_name}, Search Term: ${searchTerm}`,
                              });
                            }}
                          >
                            {item.appear_times}
                          </Link>
                        );
                      };

                      return <HoverableLink />;
                    })()}
                  </StyledTableCellBelow>{" "}
                  <StyledTableCellBelow
                    columnType="2y"
                    style={{ paddingLeft: "10px" }}
                  >
                    {item.name ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {parseInt(item.two_year_trend_change) >= 0 ? (
                          <ArrowUpwardIcon
                            style={{ color: "darkgreen", marginRight: "4px" }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            style={{ color: "red", marginRight: "4px" }}
                          />
                        )}
                        {Math.abs(parseInt(item.two_year_trend_change)) + "%"}
                      </div>
                    ) : (
                      ""
                    )}
                  </StyledTableCellBelow>
                  {/* Corrected field reference */}
                  <StyledTableCellBelow columnType="1y">
                    {item.name ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {parseInt(item.one_year_trend_change) >= 0 ? (
                          <ArrowUpwardIcon
                            style={{ color: "darkgreen", marginRight: "4px" }}
                          />
                        ) : (
                          <ArrowDownwardIcon
                            style={{ color: "red", marginRight: "4px" }}
                          />
                        )}
                        {Math.abs(parseInt(item.one_year_trend_change)) + "%"}
                      </div>
                    ) : (
                      ""
                    )}
                  </StyledTableCellBelow>
                </StyledTableRow>
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TechScoutingResult() {
  const query = useQuery();
  const keywords = query.get("keywords");
  const [searchTerm, setSearchTerm] = useState(keywords || "");
  const [searchValue, setSearchValue] = useState(keywords || "");
  const [data, setData] = useState([]);
  const [relevance, setRelevance] = useState({ clusters: [] });
  const [selectedConcept, setSelectedConcept] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState("TechScouting");
  const navigate = useNavigate();
  const [concept, setConcept] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRight, setIsLoadingRight] = useState(false);
  const [sortOrder2y, setSortOrder2y] = useState("default");
  const [sortOrder1y, setSortOrder1y] = useState("default");
  const [sortOrderAppearTimes, setSortOrderAppearTimes] = useState("default");
  const [selectedLabel, setSelectedLabel] = useState(null); // To store the selected label from the left table
  const [rightTableData, setRightTableData] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);

  const fetchAutocompleteOptions = async (prefix) => {
    if (!prefix) return;
    setLoading(true);

    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_V2}/v1/autocomplete`,
        {
          headers,
          params: { prefix: prefix },
        }
      );
      setOptions(response.data.results);
    } catch (error) {
      console.error("Error fetching autocomplete options:", error);
    } finally {
      setLoading(false);
    }
  };
  const debouncedFetchAutocompleteOptions = useCallback(
    debounce((newInputValue) => fetchAutocompleteOptions(newInputValue), 300),
    []
  );
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const isExpired = decodedToken.exp * 1000 < new Date().getTime();
      if (isExpired) {
        navigate("/Login?reset=accountExpired");
      }
    }
  }, [navigate]);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const accessToken = localStorage.getItem("access_token");
        const headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        const [keywordsResponse, relevanceResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL_V2}/v1/technology/scouting/relevance/keywords`,
            {
              params: { phrases: searchTerm },
              headers: headers,
            }
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL_V2}/v1/technology/scouting/concepts`,
            {
              params: { phrases: searchTerm },
              headers: headers,
            }
          ),
        ]);
        if (typeof relevanceResponse.data == "string") {
          setRelevance({ clusters: [] });
        } else {
          setRelevance(relevanceResponse.data);
        }
        setData(keywordsResponse.data.keywords);
        if (relevanceResponse.data && relevanceResponse.data.clusters) {
          setSelectedConcept(relevanceResponse.data.clusters[0]["label"]);
          setSelectedLabel(relevanceResponse.data.clusters[0].name);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("access_token");
          alert("Please Login Again.");
          window.location.reload();
        }
      } finally {
        setIsLoading(false);
      }
    }

    if (searchTerm) {
      fetchData();
    }
  }, [searchTerm]);

  // Effect for fetching right table data based on selectedConcept (after the initial load)
  useEffect(() => {
    async function fetchRightTableData(label) {
      setIsLoadingRight(true);

      try {
        const accessToken = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL_V2}/v1/technology/scouting/concept/keywords`,
          {
            params: {
              phrases: searchTerm,
              cluster: label,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setRightTableData(response.data);
      } catch (error) {
        console.error("Error fetching right table data:", error);
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("access_token");
          alert("Please Login Again.");
          window.location.reload();
        }
      } finally {
        setIsLoadingRight(false);
      }
    }

    if (selectedConcept) {
      fetchRightTableData(selectedConcept);
    }
  }, [selectedConcept]);

  const handleChange = (newInputValue) => {
    setConcept(newInputValue);
    debouncedFetchAutocompleteOptions(newInputValue);
  };
  // const handleOptionChange = (event, newValue) => {
  //   setValue(newValue);

  //   // If an option is selected, set the searchTerm based on Abbv or Phrase
  //   if (typeof newValue === "object" && newValue !== null) {
  //     setSearchTerm(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //     setSearchValue(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //     setConcept(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
  //   } else if (typeof newValue === "string") {
  //     // If a string is entered (free solo), use it directly
  //     setSearchTerm(newValue);
  //     setSearchValue(newValue);
  //     setConcept(newValue);
  //   } else {
  //     setSearchTerm("");
  //   }
  // };
  const handleOptionChange = (event, newValue) => {
    setValue(newValue);
    
    // If an option is selected, set the searchTerm based on Abbv or Phrase
    let termToSearch = '';
    if (typeof newValue === 'object' && newValue !== null) {
      termToSearch = newValue.Abbv ? newValue.Abbv : newValue.Phrase;
      setSearchTerm(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
      setSearchValue(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
      setConcept(newValue.Abbv ? newValue.Abbv : newValue.Phrase);
    } else if (typeof newValue === 'string') {
      // If a string is entered (free solo), use it directly
      termToSearch = newValue;
      setSearchTerm(newValue);
      setSearchValue(newValue);
      setConcept(newValue);
    }
  
    if (termToSearch) {
      // Navigate to the appropriate page based on the selected button
      if (selectedButton === "TechScouting") {
        navigate(`/TechScoutingResult?keywords=${encodeURIComponent(termToSearch)}`);
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(termToSearch)}`);
      }
    } else {
      setSearchTerm('');
    }
  };
  useEffect(() => {
    if (relevance.length > 0) {
      setSelectedConcept(relevance[0].cluster_name);
    } else {
      setRightTableData([]);
    }
  }, [relevance]);

  const handleSearch = () => {
    setSearchTerm(concept);
    setSearchValue(concept);
    TrackGoogleAnalyticsEvent(
      selectedButton,
      "search_performed",
      "Search Term",
      { search_term: concept }
    );
    if (selectedButton === "TechScouting") {
      navigate(`/TechScoutingResult?keywords=${concept}`);
    } else if (selectedButton === "TechReadiness") {
      navigate(`/TechReadiness?keyword=${concept}`);
    }
  };
  const TrackGoogleAnalyticsEvent = (category, event_name, label, data) => {
    let event_params = {
      category,
      label,
      ...data,
    };
    // Send GA4 Event
    ReactGA.event(event_name, event_params);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setSearchTerm(concept);
      setSearchValue(concept);
      setSortOrder2y("default");
      setSortOrder1y("default");
      setSortOrderAppearTimes("default");
      console.log(searchValue)
      const category = selectedButton; // "TechScouting" or "TechReadiness"
      const event_name = "search_performed";

      // Triggering the GA event
      TrackGoogleAnalyticsEvent(category, event_name, "Search Term", {
        search_term: concept,
      });
      if (selectedButton === "TechScouting") {
        navigate(
          `/TechScoutingResult?keywords=${encodeURIComponent(concept)}`
        );
      } else if (selectedButton === "TechReadiness") {
        navigate(`/TechReadiness?keyword=${encodeURIComponent(concept)}`);
      }
    }
  };
  // const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     handleSearch();
  //   }
  // };

  const [selectedTab, setSelectedTab] = useState("tech");

  const tabStyle = (tab) => ({
    fontFamily: "Glory",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0.01em",
    paddingBottom: "25px",
    textAlign: "left",
    color: selectedTab === tab ? "#435452" : "#00000066",
    borderBottom: selectedTab === tab ? "4px solid #3D766F" : "none",
    cursor: "pointer",
  });
  // Assuming you have a function to handle the modal opening
  const handleOpenModal = () => {
    setOpenModal(true);
    ReactGA.event({
      category: "User Interaction",
      action: "Opened Modal",
      label: "Search Modal",
    });
  };

  return (
    <div className="white-background">
      <Navbar openModal={handleOpenModal} />
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setOptions([]);
        }}
        aria-labelledby="search-modal"
        aria-describedby="search-functionality"
        align="right"
        sx={{
          position: "absolute",
          top: "70px",
          right: "30px",
        }}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <div
          style={{
            backgroundColor: "white",
            width: "420px",
            height: "220px",
            borderRadius: "8px",
            padding: "10px",
            outline: "none",
            textAlign: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            id="search-modal"
            align="left"
            style={{
              paddingLeft: "13px",
              paddingBottom: "20px",
              fontFamily: "Glory",
              fontWeight: "700",
            }}
          >
            Search
          </Typography>
          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="text primary button group"
          >
            <Button
              onClick={() => setSelectedButton("TechScouting")}
              style={
                selectedButton === "TechScouting"
                  ? {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "180px",
                      height: "40px",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Scouting
            </Button>
            <Button
              onClick={() => setSelectedButton("TechReadiness")}
              style={
                selectedButton === "TechReadiness"
                  ? {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#3D766F",
                      textTransform: "none",
                    }
                  : {
                      width: "220px",
                      height: "40px",
                      borderTopLeftRadius: "4px",
                      borderBottomLeftRadius: "0",
                      fontSize: "16px",
                      whiteSpace: "nowrap",
                      fontFamily: "Glory",
                      fontWeight: "bold",
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      background: "#F5F5F5",
                      color: "#00000066",
                      textTransform: "none",
                    }
              }
            >
              Tech Readiness Assessment
            </Button>
          </ButtonGroup>

          <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option.Abbv
                ? `${option.Abbv} - ${option.Phrase}`
                : option.Phrase
            } 
            onInputChange={(event, newInputValue) =>
              handleChange(newInputValue)
            }
            onChange={handleOptionChange}
            onKeyDown={handleKeyPress}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  selectedButton === "TechReadiness"
                    ? "Enter a specific technology to assess technology maturity"
                    : "Enter one or more fields of interest, separated by ,"
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  style: {
                    width: "400px",
                    borderRadius: "0 0 4px 4px",
                    padding: "5px 10px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    fontFamily: "Glory",
                    marginLeft: "10px",
                  },
                }}
              />
            )}
          />

          <Button
            variant="contained"
            style={{
              width: "400px",
              height: "48px",
              marginTop: "30px",
              borderRadius: "4px",
              backgroundColor: "#435452",
              color: "white",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
      </Modal>
      <div className="svg-with-search">
        <NewSVG className="background-svg" />
        <div className="tech-title-2">Tech Scouting</div>
        <div className="tech-search-bar">
          <SearchIcon className="search-icon" />
          <Autocomplete
            freeSolo
            options={options}
            loading={loading}
            fullWidth
            value={searchValue}
            getOptionLabel={(option) =>
              typeof option === "string"
                ? option
                : option.Abbv
                ? `${option.Abbv} - ${option.Phrase}`
                : option.Phrase
            } 
            onInputChange={(event, newInputValue) =>
              handleChange(newInputValue)
            }
            onChange={handleOptionChange}
            renderInput={(params) => (
              <InputBase
                {...params.InputProps}
                fullWidth
                placeholder="Enter one or more fields of interest, separated by ,"
                value={searchValue}
                onKeyDown={handleKeyPress}
                inputProps={{
                  ...params.inputProps,
                  style: { color: "white" },
                }}
                sx={{
                  ".MuiAutocomplete-clearIndicator": {
                    color: "white",
                  },
                }}
                endAdornment={
                  <>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                        sx={{
                          color: "white",
                        }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                }
              />
            )}
          />
        </div>
      </div>
      {
        // eslint-disable-next-line
        (relevance?.total === 0 || relevance?.clusters?.length === 0) &&
        isLoading != 1 ? (
          <div
            className="tableContainer"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "480px",
            }}
          >
            <EmptySVG style={{ marginRight: "5%", marginTop: "8%" }} />
            <div
              style={{
                marginRight: "100px", // Adjust space between SVG and text as needed
                marginTop: "8%",
                flexShrink: 1, // Allow text to shrink if needed
              }}
            >
              <h2>Sorry, no result found.</h2>
              <p>Please try again with another keywords.</p>
            </div>
          </div>
        ) : (
          <>
            <div
              className="tech-group-1"
              style={{ borderBottom: "1px solid #0000001A", width: "86%" }}
            >
              <div
                onClick={() => {
                  setSelectedTab("tech");
                  ReactGA.event({
                    category: "User Interaction",
                    action: "Click",
                    label: "Top technical concept",
                  });
                }}
                style={tabStyle("tech")}
              >
                Top technical concepts{" "}
                <span
                  style={{
                    marginLeft: "5px",
                    fontFamily: "Glory",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#00000066",
                    marginRight: "20px",
                  }}
                >
                  {isLoading ? "(0)" : `(${relevance.clusters?.length ?? 0})`}
                </span>
              </div>
              <div style={{ display: "inline-block", width: "20px" }}></div>{" "}
              <div
                onClick={() => {
                  setSelectedTab("relevance");
                  ReactGA.event({
                    category: "User Interaction",
                    action: "Click",
                    label: "Top relevance Technology",
                  });
                }}
                style={tabStyle("relevance")}
              >
                Top relevance technology{" "}
                <span
                  style={{
                    marginLeft: "5px",
                    fontFamily: "Glory",
                    fontSize: "18px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    letterSpacing: "0.01em",
                    textAlign: "left",
                    color: "#00000066",
                  }}
                >
                  {isLoading ? "(0)" : "(30)"}
                </span>
              </div>
            </div>
            <div className="tech-group-1-2">
              {selectedTab === "tech" ? (
                <span>
                  Core areas of technical focus, aggregated from relevant
                  keywords into a unified, overarching concept
                </span>
              ) : (
                <span>
                  Identify the most impactful technological keywords within one
                  or multiple fields
                </span>
              )}
            </div>
            <div
              className="tableContainer"
              style={{ display: "flex", maxHeight: "480px" }}
            >
              <>
                {selectedTab === "tech" ? (
                  <>
                    <Paper
                      style={{
                        flex: 1,
                        marginBottom: "10px",
                        maxWidth: "50%",
                      }}
                    >
                      <LeftTable
                        data={relevance}
                        sortOrder2y={sortOrder2y}
                        setSortOrder2y={setSortOrder2y}
                        sortOrder1y={sortOrder1y}
                        setSortOrder1y={setSortOrder1y}
                        sortOrderAppearTimes={sortOrderAppearTimes}
                        setSortOrderAppearTimes={setSortOrderAppearTimes}
                        onRowClick={(concept) => setSelectedConcept(concept)}
                        onNameClick={(name) => setSelectedLabel(name)}
                        isLoading={isLoading}
                      />
                    </Paper>
                    <Paper
                      style={{ flex: 1, marginBottom: "10px", maxWidth: "50%" }}
                    >
                      <RightTable
                        selectedConcept={selectedConcept}
                        data={rightTableData}
                        searchTerm={searchTerm}
                        isLoading={isLoading}
                        isLoadingRight={isLoadingRight}
                        selectedLabel={selectedLabel}
                      />
                    </Paper>
                  </>
                ) : (
                  <Paper style={{ flex: 1, marginBottom: "10px" }}>
                    <BelowTable
                      data={data}
                      searchTerm={searchTerm}
                      isLoading={isLoading}
                      isLoadingRight={isLoadingRight}
                    />
                  </Paper>
                )}
              </>
            </div>
          </>
        )
      }
      <div style={{ height: "100px", marginTop: "300px" }}></div>
    </div>
  );
}

export default TechScoutingResult;
